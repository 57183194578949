<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="480px" transition="dialog-transition" color="black--text" @input="$emit('close-dialog')">
      <v-card>
        <v-card-title>
          <v-list-item class="pa-0">
            <v-spacer />
            <v-list-item-action>
              <v-btn icon color="black" @click="$emit('close-dialog')">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-card-text color="black--text">
          <v-row class="mb-4">
            <v-col>
              <v-row class="px-3 align-center">
                <v-col cols="3">
                  <v-avatar size="80">
                    <img :src="item.logo">
                  </v-avatar>
                </v-col>
                <v-col cols="9">
                  <v-row class="px-3">
                    <h2 class="font-weight-bold" v-text="item.name" />
                  </v-row>
                  <v-row v-if="item.legalName" class="px-3">
                    <span class="lightgrey--text text-caption" v-text="item.legalName" />
                  </v-row>
                  <v-row v-if="item.legalEntityId" class="px-3">
                    <span class="lightgrey--text text-caption font-weight-bold" v-text="`CIF/NIF: ${item.legalEntityId}`" />
                  </v-row>
                  <v-row class="px-3">
                    <span
                      v-if="item.isFederated"
                      class="font-weight-light lightgrey--text text-caption" v-text="`${$t('club.editClub.federated')} - ${item.federationPlace}`"
                    />
                    <span v-else v-text="$t('project.enrollments.notFederated')" />
                  </v-row>
                  <v-row v-if="item.website" class="px-3">
                    <a class="text-caption" :href="item.website" target="_blank" v-text="item.website" />
                  </v-row>
                </v-col>
              </v-row>
              <template v-if="[true, false].includes(item.requiresAccomodation)">
                <v-row class="px-3 pt-6">
                  <h3 class="font-weight-bold" v-text="$t('club.accomodation.title')" />
                </v-row>
                <v-row class="px-3">
                  <ul>
                    <li v-text="$t(`club.accomodation.isRequired.${!!item.requiresAccomodation}`)" />
                  </ul>
                </v-row>
              </template>
              <v-row class="px-3 pt-6">
                <h3 class="font-weight-bold" v-text="$t('common.teams')" />
              </v-row>
              <v-row class="px-3 pb-10">
                <ul>
                  <li
                    v-for="[cohortId, teamCount] in Object.entries(item.teamCountByCohortId)" :key="cohortId"
                    v-text="`${cohorts.find(cohort => cohort.id === cohortId).name} - ${teamCount} ${$t('common.teams').toLowerCase()}`"
                  />
                </ul>
              </v-row>
              <div v-for="(user, i) in item.staff" :key="user.id" class="pb-10">
                <v-row class="px-3">
                  <h3 class="font-weight-bold" v-text="`${$t('club.staff')} ${i+1}`" />
                </v-row>
                <v-row class="px-3">
                  <v-icon left color="primary">
                    person_outline
                  </v-icon>
                  <span v-text="`${user.firstName} ${user.lastName}`" />
                </v-row>
                <v-row class="px-3 pb-2">
                  <span class="font-weight-light lightgrey--text text-caption" v-text="user.position" />
                </v-row>
                <v-row class="px-3 pb-2">
                  <v-icon left color="primary">
                    mail_outline
                  </v-icon>
                  <a class="primary--text" :href="'mailto:' + user.email" target="_blank" v-text="user.email" />
                </v-row>
                <v-row class="px-3 pb-4">
                  <v-icon left color="primary">
                    phone
                  </v-icon>
                  <span v-text="`+${user.phone.code} `" />
                  <span class="font-weight-bold ml-1" v-text="user.phone.number" />
                </v-row>
              </div>
              <v-row class="px-3">
                <h3 class="font-weight-bold" v-text="$t('common.address')" />
              </v-row>
              <v-row color="primary" class="px-3 flex-nowrap">
                <v-icon left color="primary">
                  place
                </v-icon>
                <span v-text="address" />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="isUserManager"
          class="justify-space-around align-center pb-10"
        >
          <v-btn
            v-if="tab == 0"
            color="primary"
            outlined
            min-width="12rem"
            elevation="5"
            @click="$emit('approve')"
          >
            {{ $t('common.accept') }}
          </v-btn>
          <v-btn
            v-if="tab == 0"
            color="primary"
            dark
            min-width="12rem"
            elevation="5"
            @click="$emit('reject')"
          >
            {{ $t('common.reject') }}
          </v-btn>
          <v-btn
            v-if="tab == 1"
            color="primary"
            dark
            min-width="12rem"
            elevation="5"
            @click="$emit('makePending')"
          >
            {{ $t('common.makePending') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ClubEnrollmentDetailDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    item: { type: Object, required: true },
    tab: { type: Number, required: true },
  },
  computed: {
    ...mapGetters({ isUserManager: 'project/isUserManager' }),
    ...mapGetters({ cohorts: 'competition/cohorts' }),
    address: ({ item }) => `${item.address.line1}, ${item.address.line2 ? `${item.address.line2}, ` : ''}
      ${item.address.zip}, ${item.address.city} (${item.address.region}) - ${item.address.country}`,
  },
}
</script>
